

























































































































































































































































































































































import { communityOwnerBoardController } from '@/modules/common/components/section/owner-section-controller'
import { walletStore } from '@/stores/wallet-store'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    avatar: () => import('@/modules/community/common/components/avatar.vue'),
    notification: () => import('@/components/notification/notification.vue'),
  },
})
export default class Drawer extends Vue {
  @PropSync('drawer') drawerDialog!: boolean
  wallet = walletStore
  controller = communityOwnerBoardController

  connectWallet() {
    this.drawerDialog = false
    this.wallet.showConnectWalletDialog(true)
  }

  login() {
    this.drawerDialog = false
    this.wallet.signIn()
  }

  disconnect() {
    this.wallet.disconnect()
  }

  copyWalletAddress() {
    if (this.wallet.userProfile) navigator.clipboard.writeText(this.wallet.userProfile.walletAddress ?? '')
  }

  getProfileId(profile) {
    if (profile['google_user']) {
      if (typeof profile['google_user'] === 'object') {
        return profile['google_user'].id
      } else {
        return profile['google_user']
      }
    } else {
      if (typeof profile['users_permissions_user'] === 'object') {
        return profile['users_permissions_user'].id
      } else {
        return profile['users_permissions_user']
      }
    }
  }

  get isCommunityRoute() {
    const routeName = (this.$route?.matched || [])[0]?.name
    return routeName === 'Community'
  }
}
